import * as actionTypes from '../redux/actionTypes';
import { call, all, put, takeLatest, select } from 'redux-saga/effects';
import logger from '../shared/logger';
import config from '../appConfig';
import Http from '../shared/http-service';
import NA_VALUE, { EMPTY_ADDRESS } from '../shared/na';
import userService from '../shared/user-service';
import { convertFirstLetterInArray, getUpdatedRoosterDays, getVanAvailableDays } from '../join-commute/helpers/commonHelper';
import { cancelReasons } from "./components/cancelReasons";
import { getActiveDaysFromRoosterDays, getPackageFromDays } from '../join-commute/helpers/vanHelpers';
import profileService from '../login/profileService';
import { isEmpty } from 'lodash';

const subscriptionURL = `${config.kongApiBaseUrl}/user/participant/subscription`;
const driver_url = `${config.kongApiBaseUrl}/driver`;
const coordApplication = `${config.kongApiBaseUrl}/user/participant/contract/v2`;

export default function* () {
  yield takeLatest(actionTypes.USER_PROFILE_LOADING, loadProfileData);
  yield takeLatest(
    actionTypes.USER_PERSONAL_INFO_LOADING,
    loadPersonalInfoData
  );
  yield takeLatest(actionTypes.PERSONAL_INFO_SAVING, savePersonalInfo);
  yield takeLatest(actionTypes.LICENSE_SAVING, saveLicense);
  yield takeLatest(actionTypes.LEAVE_VANPOOL, leaveVanpool);
  yield takeLatest(actionTypes.TOGGLE_PREFERENCE, togglePreference);
  yield takeLatest(actionTypes.STATES_LOADING, loadStates);
  yield takeLatest(actionTypes.GET_VANPOOL_RATES_INFO, getVanpoolRatesInfo);
  yield takeLatest(actionTypes.APPLY_TO_DRIVE, applyToDrive);
  yield takeLatest(actionTypes.UPDATE_LICENSE, updateLicense);
  yield takeLatest(actionTypes.CANCEL_SUBSCRIPTION, cancelSubscription);
  yield takeLatest(actionTypes.CHANGE_COMMUTE_DAYS, changeCommuteDays);
  yield takeLatest(actionTypes.GET_SUBSCRIPTION_DETAILS, getSubscriptionDetails);
  yield takeLatest(actionTypes.CHANGE_SUBSCRIPTION_TYPE, changeSubscriptionType);
  yield takeLatest(actionTypes.SUMBIT_SUBSCRIPTION_COORDINATOR_APPLICATION, submitCoordinatorApplication);
  yield takeLatest(actionTypes.SAVE_USER_COMMUTE_PROFILE, saveUserCommuteProfile);
  // yield takeLatest(actionTypes.LOAD_SUBSCRIPTION, loadSubscription);
}

function* savePersonalInfo({ data }) {
  try {
    const { homeAddress, mailingAddress, phone, secondPhone } = data;

    phone.isPreferred = true;
    secondPhone.isPreferred = false;

    yield Http.put(`${config.kongApiBaseUrl}/user/profile`, {
      phones: secondPhone.number ? [phone, secondPhone] : [phone],
      homeAddress,
      mailingAddress
    });
  } catch (ex) {
    logger.log(ex);
  }

  yield put({ type: actionTypes.USER_PROFILE_LOADING });
}

function* loadPersonalInfoData() {
  try {
    const profileUrl = `${config.kongApiBaseUrl}/user/profile`;
    const preferenceUrl = `${config.kongApiBaseUrl}/user/participant/profile/preferences`;

    let [profile, preferences] = yield all([
      call(() => Http.get(profileUrl)),
      call(() => Http.get(preferenceUrl)),
      put({ type: actionTypes.STATES_LOADING })
    ]);

    yield put({
      type: actionTypes.USER_PROFILE_LOADED,
      data: {
        phone: profile.phones ? profile.phones[0] : { number: NA_VALUE },
        secondPhone:
          profile.phones && profile.phones[1]
            ? profile.phones[1]
            : { number: '' },
        homeAddress: profile.homeAddress
          ? {
            ...profile.homeAddress,
            state: standardizeStateCase(profile.homeAddress.state)
          }
          : EMPTY_ADDRESS,
        mailingAddress: profile.mailingAddress
          ? {
            ...profile.mailingAddress,
            state: standardizeStateCase(profile.mailingAddress.state)
          }
          : EMPTY_ADDRESS,
        preferences: preferences.preferences
      }
    });
  } catch (ex) { }
}

// function* loadSubscription() {
//   try {
//     const user = userService.getUserProfile();
//     const subscriptionAPIInfo = yield call(() => user.isSubscription && Http.get(subscriptionURL));
//     if (subscriptionAPIInfo) {
//       const vanAvailableDays = subscriptionAPIInfo && subscriptionAPIInfo.vanpool && subscriptionAPIInfo.vanpool.operationalDays && getVanAvailableDays(subscriptionAPIInfo.vanpool.operationalDays);
//       const isVanStartingInFuture = subscriptionAPIInfo.package === null && subscriptionAPIInfo.pendingChange !== null;
//       const isVanCancelled = subscriptionAPIInfo.package !== null && subscriptionAPIInfo.pendingChange === null && subscriptionAPIInfo.endDate !== null;
//       const presentOrPendingCommuteDays = subscriptionAPIInfo.package === null ? getActiveDaysFromRoosterDays(subscriptionAPIInfo.pendingChange.participantCommuteDays) : getActiveDaysFromRoosterDays(subscriptionAPIInfo.participantCommuteDays);
//       yield put({ type: actionTypes.LOAD_SUBSCRIPTION_SUCCESS,
//         data: {
//         subscriptionAPIInfo,
//         presentOrPendingCommuteDays,
//         isVanStartingInFuture,
//         isVanCancelled,
//         vanAvailableDays } });
//       let newProfile = profileService.addSubscriptionPermissions(user, subscriptionAPIInfo);
//       newProfile = profileService.addProfilePermissions(newProfile, profile);
//       yield put({ type: actionTypes.SET_USER_AUTHORIZATION, data: newProfile });
//     }
//     else {
//       yield put({ type: actionTypes.LOAD_SUBSCRIPTION_ERROR });
//     }
//   } catch (e) {
//     logger.log(e);
//     yield put({ type: actionTypes.LOAD_SUBSCRIPTION_ERROR });
//   }
// }

function* loadProfileData(action) {
  try {
    const user = userService.getUserProfile();
    const profileUrl = `${config.kongApiBaseUrl}/user/profile`;
    const driverUrl = `${config.kongApiBaseUrl}/user/profile/license`;
    const vanpoolUrl = `${config.kongApiBaseUrl}/vanpools/vanpool`;
    const preferenceUrl = `${config.kongApiBaseUrl}/user/participant/profile/preferences`;
    const commuteUrl = `${config.kongApiBaseUrl}/user/profile/commuteProfile`;
    const participantsUrl = `${config.kongApiBaseUrl}/vanpools/vanpool/participants`;
    const summaryUrl = `${config.kongApiBaseUrl}/user/participant/monthly/subscription/summary`;
    let vanpool = { vanpoolName: null };
    let driver = { partialLicenseNumber: '', stateOfIssue: '' };

    let [profile, preferences, commuteDetails, participants, subscriptionSummary, subscriptionAPIInfo] = yield all([
      call(() => user && Http.get(profileUrl)),
      call(() => user && Http.get(preferenceUrl)),
      call(() => user && Http.get(commuteUrl)),
      call(() => user && user.isInVanpool && user.vanpoolId && Http.get(participantsUrl)),
      call(() => user && user.isSubscription && Http.get(summaryUrl)),
      call(() => user && user.isSubscription && Http.get(subscriptionURL)),
      user && put({ type: actionTypes.STATES_LOADING })
    ]);
    if (user && user.isInVanpool) {
      vanpool = yield call(() => Http.get(vanpoolUrl));
      if (!(profile && (profile.driverStatus === ""))) {
        try {
          driver = yield call(() => Http.get(driverUrl));
        } catch(exception) {
          logger.log(exception);
        }
      }
    }
    const name = profile && (profile.firstName
      ? `${profile.firstName} ${profile.middleName ? profile.middleName[0] + ' ' : ''
      }${profile.lastName}`
      : NA_VALUE);

    const coordinatorStatus =
    profile && profile.coordinatorStatus &&
        !profile.coordinatorStatus.includes('Approved')
        ? `Coordinator ${profile.coordinatorStatus}`
        : '';
    const driverStatus =
    profile && profile.driverStatus && !profile.driverStatus.includes('Approved')
        ? `Driver ${profile.driverStatus}`
        : '';

    const participant = participants ? participants.participants.find(p => p.participantId == profile.participantId) : [];
    let rosterDays = {};
    if(commuteDetails && commuteDetails.commuteDays && commuteDetails.commuteDays.length > 0){
      rosterDays = getUpdatedRoosterDays(commuteDetails.commuteDays, true);
    } else if(subscriptionAPIInfo && subscriptionAPIInfo.participantCommuteDays && !isEmpty(subscriptionAPIInfo.participantCommuteDays)){
      rosterDays = subscriptionAPIInfo.participantCommuteDays;
    }
    const vanAvailableDays = subscriptionAPIInfo && subscriptionAPIInfo.vanpool && subscriptionAPIInfo.vanpool.operationalDays && getVanAvailableDays(subscriptionAPIInfo.vanpool.operationalDays);
    const isVanStartingInFuture = subscriptionAPIInfo && subscriptionAPIInfo.package === null && subscriptionAPIInfo.pendingChange !== null;
    const isVanCancelled = subscriptionAPIInfo && subscriptionAPIInfo.package !== null && subscriptionAPIInfo.pendingChange === null && subscriptionAPIInfo.endDate !== null;
    const presentOrPendingCommuteDays = subscriptionAPIInfo && (subscriptionAPIInfo.package === null && subscriptionAPIInfo.pendingChange ? getActiveDaysFromRoosterDays(subscriptionAPIInfo.pendingChange.participantCommuteDays) : getActiveDaysFromRoosterDays(subscriptionAPIInfo.participantCommuteDays));
    const participantsDays = getActiveDaysFromRoosterDays(rosterDays);
    const pack = (subscriptionAPIInfo && subscriptionAPIInfo.package) || (getPackageFromDays(participantsDays));
    yield put({
      type: actionTypes.USER_PROFILE_LOADED,
      data: {
        name,
        fname: profile && profile.firstName,
        phone: profile && profile.phones ? profile.phones[0] : { number: NA_VALUE },
        secondPhone:
        profile && profile.phones && profile.phones[1]
            ? profile.phones[1]
            : { number: '' },
        email: profile && profile.emailAddress || NA_VALUE,
        homeAddress: profile && profile.homeAddress
          ? {
            ...profile.homeAddress,
            state: standardizeStateCase(profile.homeAddress.state)
          }
          : EMPTY_ADDRESS,
        mailingAddress: profile && profile.mailingAddress
          ? {
            ...profile.mailingAddress,
            state: standardizeStateCase(profile.mailingAddress.state)
          }
          : EMPTY_ADDRESS,
        vanpoolId: profile && profile.vanpoolId,
        preferences: preferences && preferences.preferences,
        participantId: profile && profile.participantId,
        vanpool: {
          name: vanpool && vanpool.vanpoolName,
          id: profile && profile.vanpoolId,
          participant: profile && profile.participantId,
          role: profile && profile.roleName,
          status: coordinatorStatus && driverStatus && 
            [coordinatorStatus, driverStatus]
              .filter(status => status)
              .join(', ') || 'No Active Applications',
          isAbleToLeave: profile && profile.isAbleToLeaveVanpool,
          matches: vanpool && vanpool.matchCount || 0,
          pickupLocation: commuteDetails && commuteDetails.originAddress,
          dropLocation: commuteDetails &&commuteDetails.destinationAddress,
          pickupTime: commuteDetails && commuteDetails.originDepartureTime,
          dropTime: commuteDetails && commuteDetails.originArrivalTime,
          commuteDays: participantsDays,
          smallCommuteDays: commuteDetails && convertFirstLetterInArray(participantsDays),
          vehicleType: vanpool && vanpool.vehicleType,
          vanpoolImage: vanpool && vanpool.carClassImage,
          package: pack,
          operationalDays: vanpool && vanpool.operationalDays,
          vanAvailableDays,
        },
        driver: {
          license: driver &&  `*****${driver.partialLicenseNumber}`,
          state: driver && driver.stateOfIssue
            ? standardizeStateCase(driver.stateOfIssue)
            : '',
          isDriver: profile && profile.roles.driver,
          licenseExpiry: driver && driver.expirationDate,
        },
        subscription: {
          subscriptionRate: subscriptionSummary ? subscriptionSummary.subscriptionRate : "",
          package: subscriptionSummary && subscriptionSummary.package,
          taxAmount: subscriptionSummary ? subscriptionSummary.taxAmount : "",
          participantRole: subscriptionAPIInfo && subscriptionAPIInfo.participantRole,
          vanpoolRates: subscriptionSummary && subscriptionSummary.vanpoolRates && subscriptionSummary.vanpoolRates[0] && subscriptionSummary.vanpoolRates[0].rates,
          isVanStartingInFuture,
          isVanCancelled,
          startDate: subscriptionAPIInfo && subscriptionAPIInfo.pendingChange && subscriptionAPIInfo.pendingChange.startDate,
          endDate: subscriptionAPIInfo && subscriptionAPIInfo.endDate,
          dashboardCommuteDays: presentOrPendingCommuteDays,
          totalAmount: subscriptionAPIInfo && subscriptionAPIInfo.totalAmount
        },
        employerName: commuteDetails && commuteDetails.employerName,
        employerId: commuteDetails && commuteDetails.employerId,
        isCoordinator: profile && profile.coordinatorStatus && profile.coordinatorStatus.includes('Approved'),
        isDriver:profile && profile.driverStatus && profile.driverStatus.includes('Approved'),
        profileImage: participant ? participant.profileImage : "",
        driverStatus: profile && profile.driverStatus,
        driverUpdatedStatus: profile && getDriverStatus(profile.driverStatus),
        commuteDetails,
        phones: profile && profile.phones,
        rosterDays,
        paymentCards: subscriptionSummary ? subscriptionSummary.storedPaymentCards : [],
        subscriptionAPIInfo,
        isSubscription: user && user.isSubscription
      }
    });

    yield put({
      type: actionTypes.LOAD_PROFILE_IMAGE,
      data: {
        profileImage: participant ? participant.profileImage : "",
      }
    });

    if (action && action.resetAction) {
      yield put(action.resetAction);
    }

    let newProfile = user && subscriptionAPIInfo && profileService.addSubscriptionPermissions(user, subscriptionAPIInfo);
    newProfile = newProfile && profile && profileService.addProfilePermissions(newProfile, profile);
    if(newProfile){
      yield put({type: actionTypes.SET_USER_AUTHORIZATION, data: newProfile});
    }

    if(commuteDetails){
      const mappedRouteResponse = {
        outboundRoutePoints: [
          {
            address: commuteDetails.originAddress,
            latitude: commuteDetails.originLatitude,
            longitude: commuteDetails.originLongitude,
            stopTime: commuteDetails.originDepartureTime,
            description: 'Home'
          },
          {
            address: commuteDetails.destinationAddress,
            latitude: commuteDetails.destinationLatitude,
            longitude: commuteDetails.destinationLongitude,
            stopTime: commuteDetails.destinationArrivalTime,
            description: commuteDetails.employerName
          }
        ],
        inboundRoutePoints: [
          {
            address: commuteDetails.destinationAddress,
            latitude: commuteDetails.destinationLatitude,
            longitude: commuteDetails.destinationLongitude,
            stopTime: commuteDetails.destinationDepartureTime,
            description: commuteDetails.employerName
          },
          {
            address: commuteDetails.originAddress,
            latitude: commuteDetails.originLatitude,
            longitude: commuteDetails.originLongitude,
            stopTime: commuteDetails.originArrivalTime,
            description: 'Home'
          }
        ]
      };
      yield put({
        type: actionTypes.PARTICIPANT_ROUTE_LOADED,
        data: mappedRouteResponse
      });
    }
    if(vanpool){
      yield put({ type: actionTypes.VANPOOL_INFO_LOADED, data: vanpool });
    }

  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.USER_PROFILE_LOADING_ERROR,  errorString: ex });
    if (action && action.history && action.source && action.source === 'joinCommute') {
      action.history.push('/JoinCommute/error');
    }
  }
}

function* loadStates() {
  try {
    const statesUrl = `${config.kongApiBaseUrl}/location/states`;

    const response = yield call(() => Http.get.cache(statesUrl));

    yield put({
      type: actionTypes.STATES_LOADED,
      data: response.states.map(state => standardizeStateCase(state.name))
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.STATES_LOADING_ERROR, data: [], errorString: ex });
  }
}

function* leaveVanpool({ data }) {
  try {
    const url = `${config.kongApiBaseUrl}/vanpools/vanpool/participant/${data}`;

  const response = yield call(() => Http.delete(url));

    yield put({ type: actionTypes.LEFT_VANPOOL, data: response.success });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.LEFT_VANPOOL, data: false });
  }
}

function* saveLicense({ data }) {
  try {
    const driverUrl = `${config.kongApiBaseUrl}/user/profile/license`;

    yield call(() =>
      Http.put(driverUrl, {
        licenseNumber: data.license,
        stateOfIssue: data.state
      })
    );
  } catch (ex) {
    logger.log(ex);
  }

  yield put({ type: actionTypes.USER_PROFILE_LOADING });
}

function* togglePreference({ data }) {
  try {
    const preferenceUrl = `${config.kongApiBaseUrl}/user/participant/profile/preferences`;

    const update = yield call(() =>
      Http.put(preferenceUrl, {
        preferences: [
          { preferenceCode: data.preferenceCode, isSelected: !data.isSelected }
        ]
      })
    );

    if (!update.success) {
      yield put({
        type: actionTypes.DISPLAY_ERROR,
        data: 'Unable to update preference.'
      });
      yield put({ type: actionTypes.RESET_PREFERENCE, data });
    }
    yield put({ type: actionTypes.PREFERENCE_CHANGED});
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.RESET_PREFERENCE, data });
    yield put({ type: actionTypes.PREFERENCE_CHANGE_ERROR });
  }
}

function standardizeStateCase(state) {
  if(state){
    return state
    .split(' ')
    .map(word => word[0].toUpperCase() + word.substr(1).toLowerCase())
    .join(' ');
  }
}

function* getVanpoolRatesInfo() {
  try {
    const state = yield select();
    let response = yield call(() =>
      Http.get(subscriptionURL, { vanpoolId: state.profile.vanpoolId })
    );
    if (response && response.vanpool && response.vanpool.vanpoolRates) {
      yield put({ type: actionTypes.SET_VANPOOL_RATES_INFO, data: response.vanpool.vanpoolRates });
    }
  }
  catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.SET_VANPOOL_RATES_INFO, data: {} });
  }
}

function* applyToDrive(action) {
  try {
    const state = yield select();
    const contractInfo = state.joinCommute.contractInfo;
    if (state && state.profile && state.profile.driverApplicationData && state.profile.driverApplicationData.apiData) {
      var payload = state.profile.driverApplicationData.apiData;
      payload.driverCoordInformation.agreementId = contractInfo.agreementId ? contractInfo.agreementId : null;
      payload.driverCoordInformation.clickwrapId = contractInfo.clickwrapId ? contractInfo.clickwrapId : null;
      const resp = yield call(() => Http.post(subscriptionURL, payload));
      if (resp && resp.success) {
        yield put({ type: actionTypes.SUBSCRIPTION_CHANGED });
        if (action && action.history && action.navigateTo) {
          action.history.push(action.navigateTo);
        }
      }
    }
  }
  catch (ex) {
    logger.log(ex);
  }
}

function* submitCoordinatorApplication(action) {
  try {
    const state = yield select();
    const contractInfo = state.joinCommute.contractInfo;
      var payload = action.data;
      payload.agreementId = contractInfo.agreementId ? contractInfo.agreementId : null;
      payload.clickwrapId = contractInfo.clickwrapId ? contractInfo.clickwrapId : null;
       const resp = yield call(() => Http.post(coordApplication, payload));
       if (resp && resp.success) {
        let userProfile = userService.getUserProfile();
        userProfile.isPendingCoordinator = false;
        if (userProfile.isPendingDriver) {
          userProfile.isPendingDriver = false;
          userProfile.isPendingDriverApproval = true;
        }
        yield put({ type: actionTypes.SET_USER_AUTHORIZATION, data: userProfile });
        if(action.application == 'coordinator'){
          action.history.push("/coordinator-application/Thankyou");
        }else {
          action.history.push("/driver-application/Thankyou");
        }
        }
  }
  catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.DRIVER_COORDINATOR_APPLICATION_FAILED, data: true });
  }
}

function* updateLicense(action) {
  try {
    const state = yield select();
    const updateURL = `${config.kongApiBaseUrl}/user/profile/license`;
    if (action.data && state.profile && state.profile.vanpoolId) {
      yield call(() => {
        Http.put(updateURL, action.data, { vanpoolId: state.profile.vanpoolId, participantId: 'P-046098' })
      })
    }
  }
  catch (ex) {
    logger.log(ex);
  }
}

function* cancelSubscription(action) {
  try {
    const cancelSubscriptionURL = `${config.kongApiBaseUrl}/vanpools/vanpool/cancelSubscription`;
    const state = yield select();
    const profile = state && state.profile;
    const lastDayRiding = profile && profile.subscription && profile.subscription.lastDay;
    const reasonID = profile && profile.subscription && profile.subscription.cancellationReasonID;
    const reasonObject = cancelReasons[reasonID];
    const reason = reasonObject && reasonObject.reason;
    const request = {
      lastDayRiding,
      reason
    }
    const response = yield call(() => Http.post(cancelSubscriptionURL, request));
    // yield delay(1000);
    // const response = {
    //   "success": true,
    //   "subscriptionEndDate": "2024-10-24",
    //   "lastDayRiding": "2024-09-25"
    // }
    if (response?.success) {
      yield put({ type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS, ...response });
    }

  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.CANCEL_SUBSCRIPTION_FAILED });
  }
}

function* changeCommuteDays(action) {
  try {
    const contractUrl = `${config.kongApiBaseUrl}/user/participant/agreement`;
    const vanpoolId = action.data.vanpoolId;
    var paylaod = {
      "isDriver": action.data.isDriver
    }
    const contractResponse = yield call(() => Http.post(contractUrl, paylaod, { vanpoolId }));
    if (contractResponse && contractResponse.agreementId && contractResponse.isAgreed) {
      const response = yield call(() => Http.post(subscriptionURL, { ...action.data, agreementId: contractResponse.agreementId, clickwrapId: contractResponse.clickwrapId }));
      if (response && response.success) {
        yield put({ type: actionTypes.CHANGE_COMMUTE_DAYS_SUCCESS });
      }
    }
  } catch (ex) {
    logger.log(ex);
  }
}

function* getSubscriptionDetails() {
  try {
    let response = yield call(() =>
      Http.get(subscriptionURL)
    );
    if (response) {
      yield put({ type: actionTypes.SET_SUBSCRIPTION_DETAILS, data: response });
    }
  }
  catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.SET_SUBSCRIPTION_DETAILS, data: null });
  }
}

function* changeSubscriptionType(action) {
  try {
    const response = yield call(() => Http.post(subscriptionURL, action.data));
    if (response && response.success) {
      // yield delay(3000);
      yield put({ type: actionTypes.CHANGE_SUBSCRIPTION_TYPE_SUCCESS });
    }
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.CHANGE_SUBSCRIPTION_TYPE_FAILURE });
  }
}

function getDriverStatus(data){
  if(!data){
    return null
  }
  var status = data.toLowerCase()
  if(status === 'approved' || status === 'expiring' || status === 'expiring today' || status === 'expiring license' ) {
        return null;
  } else if (status === 'pending' || status === 'pending application' || status === 'pending approval' || status === 'pending mvr' || status.includes("exception")) {
        return "Pending Driver Approval";
  } else if(status === 'expired' || status === 'expired license') {
        return "License Expired";
  } else if(status === 'declined'){
        return "Non-Approved Driver";
  }
}


function* saveUserCommuteProfile(action) {
  var data = action.data;
  const commuteUrl = `${config.kongApiBaseUrl}/user/profile/commuteProfile`;
  const profile = userService.getUserProfile();
  const userInfo = userService.getAuthentication();

  let payload = {
    referralChannel: 'Friend',
    phone: profile.phone,
    defaultTransportationModeId: 2,
    originLatitude: data.homeLatitude,
    originLongitude: data.homeLongitude,
    originArrivalTime: data.arriveTime,
    originDepartureTime: data.departTime,
    originAddress: data.homeAddress ? data.homeAddress : null,
    destinationAddress: data.workAddress ? data.workAddress : null,
    destinationArrivalTime: data.arriveTime,
    destinationLongitude: data.workLongitude,
    destinationLatitude: data.workLatitude,
    destinationDepartureTime: data.departTime,
    employerName: data.employerName ? data.employerName : null,
    profileId: userInfo.profileId,
    isAllowNotifyCommuteMatch: false,
    isWorkDepartTimeFlexible: data.flexArrivalMinutes
      ? data.flexArrivalMinutes
      : false,
    worksiteId: null,
    employerId: data.employerId,
    commuteDays:data.activeDays,
    isWorkArriveTimeFlexible: data.flexDepartureMinutes
      ? data.flexDepartureMinutes
      : false
  };
  try {
    var response = yield call(() => Http.put(commuteUrl, payload));
    if(response.success) {
      yield put({ type: actionTypes.COMMUTE_USER_PROFILE_SAVED, data: true });
      yield put({ type: actionTypes.COMMUTE_PROFILE_LOADING });
    }
 
  } catch (ex) {
    logger.log(ex);
  }
}